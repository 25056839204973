import React from "react"
import Layout from "../components/layout"

const Hugskot = () => {
    return(
        <Layout>
            <h1>Hugskot</h1>
        </Layout>
    )
}

export default Hugskot